import * as React from "react";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import ImgContent from "../../components/GSTTemp/ImgContent";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
import Package from '../../components/GSTTemp/Package';

export default function PfReturnFiling() {
  /* Sliders */
  const PackageCompData = {
    header: `ESIC & EPFO registration`,
    buyBtnLink: `#pricing-buy`,
    price: `4999`,
    f_price: `7999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* Tabs Data */
  const PFReturnTabsData = {
    id: 'features',
    heading: 'PF Registration Procedure',
    paragraph: `The procedure listed below should be followed by Indian
    employers that wish to apply for EPFO registration:`,
    body: [
      {
        tab_title: 'Registration',
        tab_content: [
          {
            content_title: 'Step-1',
            content_paragraph: `To register the business, the employer must visit the EPFO
          website at https://www.epfindia.gov.in/site en/index.php.
          Next, from the website's drop-down menu, choose
          "Establishment Registration."`,
            points: [],
          },
          {
            content_title: 'Step-2',
            content_paragraph: ` You can download the handbook by choosing "Establishment
          Registration" and proceeding to the following page at
          https://registration.shramsuvidha.gov.in/user/register.
          Before registering, a new user must carefully read the
          user handbook.`,
            points: [],
          },
          {
            content_title: 'Step-3',
            content_paragraph: `Now click on the button named, ‘Sign Up’.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/registration.png',
        alt_img: 'TODAYFILINGS PF Return Filing Registration',
      },
      {
        tab_title: 'Sign Up',
        tab_content: [
          {
            content_title: 'Step-4',
            content_paragraph: ` You will be asked for your Name, Mobile Number, Email, and
          Verification Code after clicking "Sign Up." After entering
          this information, click "Sign Up" to create your account.`,
            points: [],
          },

          {
            content_title: 'Step-5',
            content_paragraph: `A "Registration For EPFO-ESIC" option will be available.`,
            points: [],
          },
          {
            content_title: 'Step-6',
            content_paragraph: `You'll find a link to "Apply for New Registration" on the
          next page. You will next be given the choice between the
          "Employees' State Insurance Act, 1948" and the "Employees'
          Provident Fund and Miscellaneous Provision Act, 1952"; you
          may check both of these before pressing the "Submit"
          button.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/digital.png',
        alt_img: 'TODAYFILINGS PF Return Filing ',
      },
      {
        tab_title: 'E-Filing',
        tab_content: [
          {
            content_title: 'Step-7',
            content_paragraph: `By clicking on this, you will be directed to a page with
          information about the employer, including Establishment
          Details, e-Contacts, Contact Persons, Identifiers,
          Employment Details, Worker Specifications,
          Branch/Division, Activities, and Attachments. All required
          fields that must be filled out are shown by a red asterisk
          next to each section.`,
            points: [],
          },
          {
            content_title: 'Step-8',
            content_paragraph: `You may get a summary of the registration form on the
          dashboard.`,
            points: [],
          },
          {
            content_title: 'Step-9',
            content_paragraph: `Click the "Submit" button to finish the registration
          process.`,
            points: [],
          },
        ],
        content_img: '/imgs/e-filing.png',
        alt_img: 'TODAYFILINGS PF Return E-Filing',
      },
      {
        tab_title: 'DSC',
        tab_content: [
          {
            content_title: 'Step-10',
            content_paragraph: `The next step is to register the employer's Digital
          Signature Certificate (DSC). For new EPF registration
          online applications, DSC registration is necessary.`,
            points: [],
          },
          {
            content_title: 'Step-11',
            content_paragraph: `Shram Suvidha will send you an email to let you know when
          your DSC registration is complete.`,
            points: [],
          },
          {
            content_title: 'Why file your PF returns with TODAYFILINGS?',
            content_paragraph: `TODAYFILINGS is a well-known business platform and innovative concept that offers
             businesses in India end-to-end integration, compliance services, and PF return outsourcing.
              Filing PF returns is easy, inexpensive, and quick with TODAYFILINGS! Please get in touch with TODAYFILINGS's
              own experts if you need advice.`,
            points: [],
          },
        ],
        content_img: '/imgs/registration/dsc/dsc.png',
        alt_img: 'Best PF Return Filing In Hosur',
      },
    ],
  };

  /* About Data Without Form*/
  const PfrAboutData = {
    title: `PF Registration`,
    sub_title: `Online PF Registration with in days.`,
    paragraph: `One of the most well-liked ways for Indians to save money is
    through the Employees Provident Fund. There are at least 20
    contract employees working in the fields of labour,
    housekeeping, and security. After the appointment date, the
    employee has one month to register. If it continues to be
    late, the business or corporation must impose a penalty.`,
    header: `Our Features in PF Registration in India`,
    points: [`Capital Appreciation`, `Corpus for Retirement`],
  };

  /* Img Content Component Data */
  const PfrIcData = {
    id: 'documents',
    mt_div: 'mt-5',
    mt_img: 'mt-1',
    header: `PF Registration Online`,
    image: '/imgs/business/sliders/producer-slider.png',
    alt_tag: 'professional tax registration',
    points: [
      {
        head: 'The following are some of the benefits of EPF:',
        content: `It facilitates
        long-term budgeting. There is no requirement to invest all at
        once. Regular deductions from an employee's salary enable them
        to accumulate sizable savings over time.`,
        icon: true,
      },
      {
        head: '',
        content: `In an emergency, it might provide cash support for a worker.`,
        icon: true,
      },
      {
        head: '',
        content: `It can help someone maintain a healthy lifestyle and save money for retirement.`,
        icon: true,
      },
      {
        head: 'Eligibility for EPF Registration Online in India',
        content: '',
        icon: false,
      },
      {
        head: '',
        content: `For salaried
        employees making less than INR 15,000 per month, registration
        for an EPF account is necessary.`,
        icon: true,
      },
      {
        head: '',
        content: `A company is
        obligated by law to enrol in the EPF plan and make employee
        contributions to it if it employs 20 or more people overall.`,
        icon: true,
      },
      {
        head: '',
        content: `Employers with
        fewer than 20 employees may voluntarily join the EPF.`,
        icon: true,
      },
      {
        head: '',
        content: `All of India must
        comply with the EPF scheme's criteria, although Jammu and
        Kashmir is exempt.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const PfrScImages = [
    '/imgs/registration/dsc/dsc-imgslider.png',
    '/imgs/registration/dsc/dsc-img-slider.png',
  ];

  /* SliderContent Component Data */
  const PfrSCData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: 'mt-1',
    mt_img: 'mt-5',
    header: 'Required documents and deadline ',
    content: [
      {
        points: `Name of the applicant Use a driver's license, passport, or election card as proof
        of ownership or a confirmation of the location of the employer. or the owner's PAN card.`,
        icon: true,
      },
      {
        points: `Address Information in full, including the applicant's home address and contact info.`,
        icon: true,
      },
      {
        head: 'Deadline For PF Filing',
        points: `The 15th day of
        every month is the due day for providing or filing monthly PF
        returns with all PF invoices pertaining to the Company
        Registration or entity and its employees.`,
        icon: true,
      },
      {
        points: `April 30 of each relevant year is the due date for annual returns.`,
        icon: true,
      },
    ],
    images: PfrScImages,
  };

  /* Document Section Data */
  const PfrDocData = {
    id: 'pfr-doc',
    heading: `PF Return Registration Schemes`,
    sub_heading: `Different Schemes Provided by EPFO`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-4`,
        delay: '100',
        img: `/imgs/icons/bregistration/form.png`,
        alt_tag: `EPF`,
        header: `EPF`,
        description: `Employees Provident Funds Scheme 1952 or EPF.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '200',
        img: `/imgs/icons/bregistration/ndh3.png`,
        alt_tag: `EPS`,
        header: `EPS`,
        description: `Employees Pension Scheme 1995 or EPS.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '300',
        img: `/imgs/icons/bregistration/register.png`,
        alt_tag: `EDLI`,
        header: `EDLI`,
        description: `Employees Deposit Linked Insurance Scheme 1976.`,
      },
    ],
  };
  /* FaQ data */
  var PfReturnFAQ = [
    {
      header: 'What is PF return filing?',
      body: [
        {
          content: `All the employers who have obtained PF registration are required to do PF return filing on
          monthly basis. The PF returns are to be filed by the 15th of each month on the unified portal.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the PF contribution?',
      body: [
        {
          content: `The employer and the employee both have to both contribute 12% of the basic salary of the
          employee in the EPF account.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Is PF registration mandatory for individuals earning more than Rs.15,000?',
      body: [
        {
          content: `PF registration is mandatory for the employees who have a basic salary of dearness allowance
          is up to 15,000 and for those who are earning beyond Rs.15,000 it is not mandatory to get PF registration.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is PF transfer required?',
      body: [
        {
          content: `If the member has more than EPF member ID i.e EPF account and the EPF account of accounts have not been
          transferred to the latest EPF account, then the member is required to get the PF transferred into the current EPF
          account.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is it possible to get PF after registration?',
      body: [
        {
          content: `You cannot apply for withdrawing the EPF account balance immediately after the resignation from the company.
           In case if the applicant is choosing to withdraw money in the PF account before completing 5 years, you will liable
           to pay tax on the amount.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How many days it will take to clear the PF amount?',
      body: [
        {
          content: `When the employee applies for EPF it takes 5-30 days to get the PF amount into the bank account. But the
          time depends on how fast the EPFO officer clears the claim. Some EPFO office clears the claim in 5 days while some
          take more days.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can PF be paid online?',
      body: [
        {
          content: `Offline transfer of the PF may be done only if the transfer from an exempted establishment to another
          exempted establishment. For other cases, it can be made online.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the due date for monthly PF returns?',
      body: [
        {
          content: `The due date for monthly PF returns is the 15th of the subsequent month.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What is the due date for monthly challan remittance to the bank for PF?',
      body: [
        {
          content: `The due date for Monthly Challan remittance to the Bank for PF is the 15th of every month.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Why is PF transfer required?',
      body: [
        {
          content: `If a member more than one EPF member ID and the EPF account has not been transferred to the
          latest EPF account then the member is required to get the PF transferred into his current EPF account.`,
          icon: false,
        },
      ],
    },
  ];

  return (
    <div>
      <Seo
        title='Pf Return Filing'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={PfrSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={PfrAboutData} />

          <Counter />

          <ImgContent item={PfrIcData} />
          <section className='contact-area ptb_100 '>
            <div className='container'>
              <div className='row text-center'>
                <div className='col-md-6'>
                  <header
                    className='section-header aos-init aos-animate'
                    data-aos='fade-up'
                    data-aos-offset='100'
                  >
                    <h2>ESIC</h2>
                  </header>

                  <div
                    className='col-md-12 mt-3 aos-init aos-animate'
                    data-aos='fade-left'
                    data-aos-offset='100'
                  >
                    <div className='desc mt-4 text-center'>
                      <h5>
                        <strong>Employees' State Insurance Corporation</strong>
                      </h5>
                      <div className='row mt-2'>
                        <div
                          className='col-md-12'
                          style={{ textAlign: 'justify' }}
                        >
                          The Employees State Insurance Act, 1948 was enacted by
                          the Government of India through the Parliament to
                          strengthen the social security for workers in
                          independent India. In order to speed up manufacturing
                          processes, manufacturing sectors started hiring many
                          workers during the industrial revolution of the 1950s.
                          The purpose of the legislation was to give these
                          numerous employees a security mechanism, particularly
                          in the event of health-related emergencies.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <header
                    className='section-header aos-init aos-animate'
                    data-aos='fade-up'
                    data-aos-offset='100'
                  >
                    <h2>EPFO</h2>
                  </header>

                  <div
                    className='col-md-12 mt-3 aos-init aos-animate'
                    data-aos='fade-left'
                    data-aos-offset='100'
                  >
                    <div className='desc mt-4 text-center'>
                      <h5>
                        <strong>Employees' Provident Fund Organization.</strong>
                      </h5>
                      <div className='row mt-2'>
                        <div
                          className='col-md-12'
                          style={{ textAlign: 'justify' }}
                        >
                          The Employees' Provident Fund was established on
                          November 15, 1951, when the Employees' Provident Funds
                          Ordinance was passed. The Employees' Provident Funds
                          Act, 1952 took its place. The Employees' Provident
                          Funds Bill, which established provident funds for
                          workers in factories and other establishments, was
                          introduced in the Parliament as Bill Number 15 of
                          1952. With the exception of Jammu and Kashmir, the
                          Employees' Provident Funds Miscellaneous Provisions
                          Act, in 1952.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Document docSecData={PfrDocData} />

          <GeneralTab HTabsData={PFReturnTabsData} />

          <SliderContent ScSection_data={PfrSCData} />
          <Cta />
          <FAQAccord items={PfReturnFAQ} />
        </main>
      </Layout>
    </div>
  );
}
